import React, { useState, useRef, useEffect } from 'react';

export default function FilterOptions({filters, handleApplyFilter, handleResetFilters}) {
  
  const [isFilterOpen, setIsFilterOpen] = useState(false); // State to manage visibility
  const filterRef = useRef(null); // Reference for the component

  // Toggle filter visibility
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  // Close filter when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false); // Close the filter menu
      }
    };
    

    // Add event listener to detect clicks outside the filter
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [selectedValues, setSelectedValues] = useState({});

  const handleFilterChange = (evt, identifier) => {
      const newValue = evt.target.value;
      setSelectedValues(prev => {
          const updatedValues = { ...prev, [identifier]: newValue };
          localStorage.setItem("selectedFilters", JSON.stringify(updatedValues)); // Persist
          return updatedValues;
      });

      const selectedFilter = filters.find(filter => filter.identifier === identifier);
      selectedFilter?.returnFunction(evt, identifier);
  };

  return (
    <div ref={filterRef} className="me-4" data-select2-id="select2-data-77-mtvn">
      {/* Toggle button */}
      <button
        className="btn btn-sm btn-flex btn-light btn-active-light fwt-bold"
        onClick={toggleFilter}
      >
        <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
              fill="black"
            ></path>
          </svg>
        </span>
        Filter
      </button>

      {/* Filter Menu */}
      {isFilterOpen && (
        <div
          className={isFilterOpen ? "menu menu-sub menu-sub-dropdown w-250px w-md-300px show" : "menu menu-sub menu-sub-dropdown w-250px w-md-300px"}
          style={{zIndex: '105', maxHeight: '375px', overflowY: 'auto', position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate3d(-25px, 100px, 0px)'}}
          data-kt-menu="true"
          id="kt_menu_61484c45b0e77"
        >
          {/* Menu Header */}
          <div className="px-7 py-3">
            <div className="fs-6 text-dark fwt-bold">Filter Options</div>
          </div>

          {/* Separator */}
          <div className="separator border-gray-200"></div>

          {/* Form */}
          <div className="px-7 py-3">
          {filters.map((filter, index) => (
                <div className="mb-10" key={index}>
                    <label className="form-label fwt-bold fs-6">{filter.title}</label>
                    <select
                        className="form-select form-select-solid"
                        value={selectedValues[filter.identifier] || ""}
                        onChange={(evt) => handleFilterChange(evt, filter.identifier)}
                    >
                        <option value="">-- Select Option --</option>
                        {filter.options?.map((option, idx) => (
                            <option 
                                key={idx} 
                                value={option.week_number || option.month_number || option.name}
                            >
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
            ))}

            {/* Actions */}
            <div className="d-flex justify-content-end">
              <button
                type="reset"
                className="btn btn-sm btn-light btn-active-light-primary me-2"
                onClick={handleResetFilters}
              >
                Reset
              </button>
              <button type="submit" onClick={handleApplyFilter} className="btn btn-sm btn-beysix text-white">
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
