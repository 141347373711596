import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import AssetsTable from '../../Assets/AssetsTable/AssetsTable';
import api from '../../../store/api';
import getAssetSearchData from '../../Utilities/getAssetSearchData';
import { useParams } from 'react-router';

export default function WarehouseAssets({active}) {

  const [assets, setAssets] = useState([])
  const token = useSelector((state) => state.loginSlice.token);
  const { id } = useParams()
  const [pageLoading, setPageLoading] = useState(false)
  const [resultsFound, setResultsFound] = useState(true)

  const getAssets = () => {
      setPageLoading(true)
      api.get(`/api/warehouses/warehouse-assets/${id}/`,
      {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
          if (response.data.length === 0) {
            setResultsFound(false)
          }
          setAssets(response.data)
          setPageLoading(false)
        })
      .catch((error) => {
          throw new Error(error) 
      })
  }

  useEffect(() => {
    getAssets()
  }, [])

  const [searchQuery, setSearchQuery] = useState('')
  const [originalAssets, setOriginalAssets] = useState([])
  const handleSearchChange = async (e) => {
    const keyword = e.target.value;
    if (keyword.length === 0) {
      setAssets(originalAssets)
    } else {
      setSearchQuery(keyword)
      const assets = await getAssetSearchData(keyword, `/api/warehouses/warehouse-assets/search/${id}/`, token)
      setAssets(assets)
    }
  }

  return (
    <>{active &&
      <AssetsTable showReceiptDate={false} showActivationFrquency={false} assetData={assets} searchQuery={'items'} loading={pageLoading} resultsFound={resultsFound} />}
    </>
  )
}
