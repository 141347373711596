import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import AssetsTable from '../../Assets/AssetsTable/AssetsTable'
import ClientOrganizationsListTable from '../../ClientOrganization/ClientOrganizationsTable/ClientOrganizationsTable'
import BrandTable from '../../Brand/BrandTable/BrandTable'
import ProjectTable from '../../Projects/ProjectTable/ProjectTable'
import ItemTable from '../../Items/ItemTable/ItemTable'
import AssertiveModal from '../../Utilities/AssertiveModal'
import FeedbackModal from '../../Utilities/FeedbackModal'
import ItemSerialTable from '../../Items/ItemSerialTable/ItemSerialTable'

export default function Table({ id, zoneName, index, handleUpdateState, occupied }) {

    const [assetList, setAssetList] = useState([])
    const [clientOrganizationList, setClientOrganizationList] = useState([])
    const [brandList, setBrandsList] = useState([])
    const token = useSelector((state) => state.loginSlice.token);
    const [activeTab, setActiveTab] = useState(1)
    const [projectList, setProjectList] = useState([])
    const [itemList, setItemList] = useState([])

    const getZoneDetails = () => {
        if (parseInt(id) !== 0) {
            api.get(`/api/warehouses/zones/zone-details/${id}/${activeTab}/`,
            {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                if (activeTab === 1) {
                    setAssetList(response.data)
                    if (response.data.length === 0) {
                      setResultsFound(false)
                    }
                    setPageLoading(false)
                  }
                if (activeTab === 2)
                    setClientOrganizationList(response.data)
                if (activeTab === 3)
                    setBrandsList(response.data)
                if (activeTab === 4)
                    setProjectList(response.data)
                if (activeTab === 5)
                    setItemList(response.data)
            })
            .catch((error) => {
                throw new Error(`Error getting data (${error}).`)
            })
        }
    }

    const [res, setRes] = useState({
        message: '',
        severity: "success",
        show: false
    })

    const [pageLoading, setPageLoading] = useState([])
    const [resultsFound, setResultsFound] = useState(true)

  const updateOccupiedStatus = (status, zoneId, index) => {
    if (status === true){
      api.post(`/api/warehouses/zones/toggle-occupy-status/${zoneId}/`, {},
        {headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
      })
      .then((response) => {
        setShowAssertiveModal({
          show: false,
          promptText: '',
          btnPrimary: {
            show: false,
            text: ''
          },
          btnSecondary: {
            show: false,
            text: ''
          },
          actionBtnFn: null
        })

        setRes({
          message: response.data.message,
          severity: "success",
          show: true
        })

        handleUpdateState(index)
      })
      .catch((error) => {
        setRes({
            message: error.response.data.error,
            severity: "error",
            show: true
          })
      })
    } else {
      setShowAssertiveModal({
        show: false,
        promptText: '',
        btnPrimary: {
          show: false,
          text: ''
        },
        btnSecondary: {
          show: false,
          text: ''
        },
        actionBtnFn: null
      })
    }
  }

  const [showAssertiveModal, setShowAssertiveModal] = useState(
    {
      show: false,
      promptText: '',
      btnPrimary: {
        show: false,
        text: ''
      },
      btnSecondary: {
        show: false,
        text: ''
      },
      actionBtnFn: null
    }
  )

    const handleOccupyBtn = (zone, index) => {
        setShowAssertiveModal({
            show: true,
            promptText: zone.occupied ? 
                `Do you want to mark ${zoneName} unoccupied?` : 
                `Do you want to mark ${zoneName} occupied?`,
            btnPrimary: {
                show: true,
                text: 'Yes, Go Ahead!'
            },
            btnSecondary: {
                show: true,
                text: 'No, Cancel.'
            },
            actionBtnFn: (status) => updateOccupiedStatus(status, id, index = 0)
        });
    };
    
    useEffect(() => {
        getZoneDetails()
    }, [id, activeTab])

    useEffect(() => {
        return () => {
            setActiveTab(1);
        };
    }, []);
  return (
    <>
        <AssertiveModal
            show = {showAssertiveModal.show}
            promptText = {showAssertiveModal.promptText}
            btnPrimary = {{show: showAssertiveModal.btnPrimary.show, text: showAssertiveModal.btnPrimary.text}}
            btnSecondary = {{show: showAssertiveModal.btnSecondary.show, text: showAssertiveModal.btnSecondary.text}}
            handleActionBtnClick = {showAssertiveModal.actionBtnFn}
        />
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
            setRes({
                message: "",
                severity: "success",
                show: false
            })
        }}/>
        <div className="card-header border-0 pt-1 pb-1" style={{justifyContent: 'end'}}>
            <div className="warehouse-zone-create-btns" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="" data-bs-original-title="Click to add a user">
            <a onClick={() => handleOccupyBtn(id, index)}className="btn btn-sm btn-light btn-active-error">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                <span className="svg-icon svg-icon-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path><path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path></svg>
                </span>Mark as {occupied ? 'unoccupied': 'fully occupied'}</a>
            </div>
      </div>
        {/* <div className="separator"></div> */}
                {/*begin::Nav wrapper*/}
                <div className="d-flex overflow-auto h-55px">
                    {/*begin::Nav links*/}
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 ftw-bold flex-nowrap">
                        <li className="nav-item">
                            <a className={activeTab === 1 ? "nav-link text-active-primary me-6".concat(" active") : "nav-link text-active-primary me-6"} onClick={() => {
                              setPageLoading(false)
                              setResultsFound(true)
                              setActiveTab(1)}
                              }>Assets</a>
                        </li>
                        {/*end::Nav item*/}
                        {/*begin::Nav item*/}
                        <li className="nav-item">
                        <a className={activeTab === 2 ? "nav-link text-active-primary me-6".concat(" active") : "nav-link text-active-primary me-6"} onClick={() => {
                          setPageLoading(false)
                          setResultsFound(true)
                          setActiveTab(2)}
                          }>Clients</a>
                        </li>
                        {/*end::Nav item*/}
                        {/*begin::Nav item*/}
                        <li className="nav-item">
                        <a className={activeTab === 3 ? "nav-link text-active-primary me-6".concat(" active") : "nav-link text-active-primary me-6"} onClick={() => {
                          setPageLoading(false)
                          setResultsFound(true)
                          setActiveTab(3)}
                          }>Brands</a>
                        </li>
                        {/*end::Nav item*/}
                        {/*begin::Nav item*/}
                        <li className="nav-item">
                        <a className={activeTab === 4 ? "nav-link text-active-primary me-6".concat(" active") : "nav-link text-active-primary me-6"} onClick={() => {
                          setPageLoading(false)
                          setResultsFound(true)
                          setActiveTab(4)}
                          }>Projects</a>
                        </li>
                        {/*end::Nav item*/}
                        {/*begin::Nav item*/}
                        <li className="nav-item">
                        <a className={activeTab === 5 ? "nav-link text-active-primary me-6".concat(" active") : "nav-link text-active-primary me-6"} onClick={() => {
                          setPageLoading(false)
                          setResultsFound(true)
                          setActiveTab(5)}
                          }>Items</a>
                        </li>
                        {/*end::Nav item*/}
                    </ul>
                    {/*end::Nav links*/}
                </div>
                {/*end::Nav wrapper*/}
                {activeTab === 1 && <AssetsTable showReceiptDate={false} showActivationFrquency={false} searchQuery={'items'} assetData={assetList}  loading={pageLoading} resultsFound={resultsFound} />}
                {activeTab === 2 && <ClientOrganizationsListTable clientData={clientOrganizationList} resultsFound={resultsFound} loading={pageLoading} />}
                {activeTab === 3 && <BrandTable brandData={brandList} loading={pageLoading} resultsFound={resultsFound} />}
                {activeTab === 4 && <ProjectTable projectData={projectList} loading={pageLoading} resultsFound={resultsFound}  />}
                {activeTab === 5 && <ItemSerialTable items={itemList} loading={pageLoading} resultsFound={resultsFound}/>}
    </>
  )
}
