import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import AssetsTable from '../../Assets/AssetsTable/AssetsTable'
import { useParams } from 'react-router'

export default function ProjectAssets() {

  const [assets, setAssets] = useState([])
  const token = useSelector((state) => state.loginSlice.token);
  const { id } = useParams()
  const [resultsFound, setResultsFound] = useState(true)

  const getAssets = () => {
      api.get(`/api/projects/project-assets/${id}/`,
      {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
      .then((response) => {
        if (response.data.length === 0) {
          setResultsFound(false)
        }
        setAssets(response.data)
      })
      .catch((error) => {
          throw new Error(error) 
      })
  }

  

  useEffect(() => {
    getAssets()
  }, [])

  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchChange = (keyword) => {
    setSearchQuery(keyword)
  }

  return (
    <AssetsTable showReceiptDate={false} showActivationFrquency={false} assetData={assets}  searchQuery={searchQuery} handleSearchChange={handleSearchChange} resultsFound={resultsFound} />
  )
}
