import React, {useEffect, useState, useRef} from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet'
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-search';
import { useSelector } from 'react-redux'
import api from '../../../../store/api';
import MainBarChart from '../../../Charts/BarChart/BarChart';
import DoughnutChart from '../../../Charts/DoughnutChart/DoughnutChart';
import SimpleLineChart from '../../../Charts/LineChart/LineChart';
import formatMoney from '../../../Utilities/FormatMoney';
import DashboardCardComponent from '../../DashboardCardComponent/DashboardCardComponent';

export default function ActivationDashboard() {

    const [activationCount, setActivationCount] = useState({})
    const [pageLoading, setPageLoading] = useState(false)
    const [activationLocationInfo, setLocationActivationInfo] = useState([])
    const [activationPerCommissioningAgency, setActivationPerCommissioningAgency] = useState([])
    const [activationPerBrand, setActivationPerBrand] = useState([])
    const [activationPerProject, setActivationPerProject] = useState([])
    const [activationPerWeek, setActivationPerWeek] = useState([])
    const [activationPerMonth, setActivationPerMonth] = useState([])
    const [activationCostPerBrand, setActivationCostPerBrand] = useState([])
    const [activationCostPerAgency, setActivationCostPerAgency] = useState([])
    const [activationPerCounty, setActivationPerCounty] = useState([])
    const [activationPerRegion, setActivationPerRegion] = useState([])
    const [activationPerActivatingAgency, setActivationPerActivatingAgency] = useState([])
    const [activationPerHandlingAgency, setActivationPerHandlingAgency] = useState([])
    const [years, setYears] = useState([])

    const mapRef = useRef(null)
    const marker = useRef(null)
    const initCoordinates = [-1.2460227009959508, 36.68168221244762];
    const [markerPosition, setMarkerPosition] = useState(null)

    const onMapReady = (map) => {
        map = map.current
        if (map){
            map.invalidateSize()
        }
    };

    const handleSetMarkerPosition = (coordinates) => {
        const { lat, long } = coordinates
        if ((lat && long) && mapRef.current) {
            setMarkerPosition({lat: parseFloat(lat), lng: parseFloat(long)})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([lat, long]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 6);
        }
    }

    const customIcon = L.icon({
        iconUrl: '/static/assets/media/illustrations/beysix-1/location-pin.png',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
    });

    const getActivationDetails = (changedYear = null) => {
        let url = `/api/dashboards/get-activation-count/${organization.id}/`
        if (changedYear !== null) {
            url = `/api/dashboards/get-activation-count/${organization.id}/${changedYear}/`
        }
        api.get(url,
          {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
          .then((response) => {
              if (response.status === 200) {
                setActivationCount(response.data.count)
                setLocationActivationInfo(response.data.activation_coordinates)
                setActivationPerCommissioningAgency(response.data.activation_per_commissioning_agency)
                setActivationPerBrand(response.data.activations_per_brand)
                setActivationPerWeek(response.data.activations_per_week )
                setActivationPerMonth(response.data.activations_per_month )
                setActivationCostPerAgency(response.data.overall_cost_per_agency)
                setActivationCostPerBrand(response.data.overall_cost_per_brand)
                setActivationPerRegion(response.data.activation_count_per_region)
                setActivationPerCounty(response.data.activation_count_per_county)
                setActivationPerProject(response.data.project_activation_data)
                setActivationPerActivatingAgency(response.data.activations_per_activating_agency)
                setActivationPerHandlingAgency(response.data.activations_per_handling_agency)
                setYears(response.data.years)
                for (let i = 0; i < response.data.activation_coordinates.length; i++) 
                    handleSetMarkerPosition(response.data.activation_coordinates[i].coords)
                setPageLoading(false)
              }
          })
          .catch((error) => {
              setPageLoading(false)
              throw new Error('Error getting data.')
          })
      }
    
      const {organization, token} = useSelector((state) => {
        return {
          'organization': state.loginSlice.organization, 
          'token': state.loginSlice.token
        }
      }); //
    
      useEffect(() => {
        getActivationDetails()
        const currentYear = new Date().getFullYear();
        setSelectedYear(currentYear)
      }, [])

      const [selectedYear, setSelectedYear] = useState(null)

  return (
    <>
    <div style={{display: 'flex', justifyContent: 'end'}}>
        <div class="mb-3" style={{width: '30%', background: '#fff', padding: '15px', borderRadius: '5px'}}>
        <label class="form-label fwt-bold fs-6">Filter By Year:</label>
        <select class="form-select form-select-solid" value={selectedYear} onChange={(evt) => {
            // setPageLoading(true)
            const value = evt.target.value;
            setSelectedYear(value)
            getActivationDetails(value)
        }}>
            <option value="all">-- Select Option --</option>
            {years.map((year) =><option value={year}>{year}</option>)}
        </select>
        </div>
    </div>
    <div className="row g-6 g-xl-9">
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Card*/}
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div className="fs-4 fw-bold">{activationCount.total_activations__count}</div>
                      <span className="svg-icon svg-icon-primary svg-icon-3hx" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-6 fwt-bold text-gray-400 mb-7">Total {activationCount.total_activations__count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
                {/*end::Card body*/}
            </DashboardCardComponent>
            {/*end::Card*/}
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Budget*/}
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">{activationCount.pending_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-6 fwt-bold text-gray-400 mb-7">Approved {activationCount.pending_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            {/*end::Budget*/}
            </DashboardCardComponent>
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Budget*/}
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">{activationCount.active_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-info" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-6 fwt-bold text-gray-400 mb-7">Active {activationCount.active_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            {/*end::Budget*/}
            </DashboardCardComponent>
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">{activationCount.completed_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-muted" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-6 fwt-bold text-gray-400 mb-7">Completed {activationCount.completed_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
                </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">{activationCount.cancelled_activations_count}</div>
                        <span className="svg-icon svg-icon-3hx svg-icon-danger" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-6 fwt-bold text-gray-400 mb-7">Cancelled {activationCount.cancelled_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
                </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">{activationCount.dispatched_activations_count}</div>
                     <span className="svg-icon svg-icon-3hx svg-icon-warning" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-6 fwt-bold text-gray-400 mb-7">Dispatched {activationCount?.dispatched_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
                </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-2 mt-2">
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">{activationCount?.set_up_activations_count}</div>
                     <span className="svg-icon svg-icon-3hx svg-icon-gray-900" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-6 fwt-bold text-gray-400 mb-7">Set Up {activationCount?.set_up_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            </DashboardCardComponent>
        </div>
        
        
        <div className="col-lg-6 col-xxl-3 mt-2">
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">KES {formatMoney(activationCount.overall_activation_cost)}</div>
                        <span className="svg-icon svg-icon-3hx svg-icon-primary" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M20 18H4C3.4 18 3 17.6 3 17V7C3 6.4 3.4 6 4 6H20C20.6 6 21 6.4 21 7V17C21 17.6 20.6 18 20 18ZM12 8C10.3 8 9 9.8 9 12C9 14.2 10.3 16 12 16C13.7 16 15 14.2 15 12C15 9.8 13.7 8 12 8Z" fill="black"/>
                        <path d="M18 6H20C20.6 6 21 6.4 21 7V9C19.3 9 18 7.7 18 6ZM6 6H4C3.4 6 3 6.4 3 7V9C4.7 9 6 7.7 6 6ZM21 17V15C19.3 15 18 16.3 18 18H20C20.6 18 21 17.6 21 17ZM3 15V17C3 17.6 3.4 18 4 18H6C6 16.3 4.7 15 3 15Z" fill="black"/>
                        </svg>
                      </span>
                    </div>
                    <div className="fs-6 fwt-bold text-gray-400 mb-7">Overall Activation Cost</div>
                </div>
                </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        <div className='col-md-12 mt-3'>
        <DashboardCardComponent isLoading={pageLoading} fitContent={true}>
                <div className="card-body d-flex flex-column" style={{padding: "0px"}}>
                    <MapContainer
                            ref={mapRef}
                            center={initCoordinates}
                            zoom={8}
                            style={{ height: "495px", width: "100%" }}
                        >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && (
                            <Marker position={markerPosition} icon={customIcon} ref={marker}>
                                {activationLocationInfo.map((activation, index) => 
                                <Popup key={index} >
                                    {activation.activation_name.concat(' Activation')}<br /> {parseFloat(activation.coords.lat).toFixed(4)}, {parseFloat(activation.coords.long).toFixed(4)}
                                </Popup>)}
                            </Marker>
                        )}
                    </MapContainer>
                    </div>
                </DashboardCardComponent>
            </div>
            
            
            {/* Agency Data */}
            <div className="col-lg-6 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activation Per Commissioning Agency</span>
                        </h5>
                    <MainBarChart _data = {activationPerCommissioningAgency} />
                </div>
                </DashboardCardComponent>
            </div>
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activation Cost Per Activating Agency</span>
                        </h5>
                    <MainBarChart _data = {activationCostPerAgency} />
                </div>
                </DashboardCardComponent>          
            </div>

            {/* Brand Data */} 
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} fitContent={true}>
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activation Cost Per Brand</span>
                        </h5>
                    <MainBarChart _data = {activationCostPerBrand} _name = "Brand_name" />
                </div>
                </DashboardCardComponent>         
            </div>
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading}>
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per brand</span>
                        </h5>
                    <MainBarChart _data = {activationPerBrand} _name = "Brand_name" overrideHeight={20 * activationPerBrand.length}/>
                </div>
                </DashboardCardComponent>        
            </div> 
            <div className="col-xl-6 mt-3">
            {/*begin::List Widget 4*/}
            <DashboardCardComponent isLoading={pageLoading} >
              {/*begin::Header*/}
              <div className="card-header border-0 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fwt-bolder text-dark">Activations per activating agency</span>
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-5" style={{maxHeight: '370px', minHeight: '370px', overflow: 'auto'}}>
                {/*begin::Item*/}
                {activationPerActivatingAgency.map((agency) =><div className="d-flex align-items-sm-center mb-7">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-50px me-5">
                    <span className="symbol-label">
                      {agency.profile_photo_path ? <img src={agency.profile_photo_path} className="align-self-center" alt={agency.name} style={{objectFit: 'cover', maxWidth: '100%', minWidth: '100%'}}/>:
                        <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"></path>
                        <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"></path></svg></span> 
                      }
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                    <div className="flex-grow-1 me-2">
                      <a  className="text-gray-800 text-hover-primary fs-6 fw-bold">{agency.name}</a>
                    </div>
                    <span className="badge badge-light fw-bold my-2">{agency.count} {agency.count === 1 ? 'Activation' : 'Activations'}</span>
                  </div>
                  {/*end::Section*/}
                </div>)}
              </div>
              {/*end::Body*/}
            </DashboardCardComponent>
            {/*end::List Widget 4*/}
          </div>
            <div className="col-xl-6 mt-3">
            {/*begin::List Widget 4*/}
            <DashboardCardComponent isLoading={pageLoading} >
              {/*begin::Header*/}
              <div className="card-header border-0 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fwt-bolder text-dark">Activations per handling agency</span>
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-5" style={{maxHeight: '370px', minHeight: '370px', overflow: 'auto'}}>
                {/*begin::Item*/}
                {activationPerHandlingAgency.map((agency) =><div className="d-flex align-items-sm-center mb-7">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-50px me-5">
                    <span className="symbol-label">
                      {agency.profile_photo_path ? <img src={agency.profile_photo_path} className="align-self-center" alt={agency.name} style={{objectFit: 'cover', maxWidth: '100%', minWidth: '100%'}}/>:
                        <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"></path>
                        <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"></path></svg></span> 
                      }
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                    <div className="flex-grow-1 me-2">
                      <a  className="text-gray-800 text-hover-primary fs-6 fw-bold">{agency.name}</a>
                    </div>
                    <span className="badge badge-light fw-bold my-2">{agency.count} {agency.count === 1 ? 'Activation' : 'Activations'}</span>
                  </div>
                  {/*end::Section*/}
                </div>)}
              </div>
              {/*end::Body*/}
            </DashboardCardComponent>
            {/*end::List Widget 4*/}
          </div>
            {/* <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per project</span>
                        </h5>
                        <MainBarChart _data = {activationPerProject} />
                </div>
                </DashboardCardComponent>          
            </div>  */}
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per County</span>
                        </h5>
                    </div>
                    <MainBarChart _data = {activationPerCounty} _name = "county" />
                </DashboardCardComponent>
                </div>  
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per Region</span>
                        </h5>
                    </div>
                    <MainBarChart _data = {activationPerRegion} _name = "region" />
                </DashboardCardComponent>
                </div>  
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Number of Activations Per Week</span>
                        </h5>
                    <SimpleLineChart _data = {activationPerWeek}  />
                </div>
                </DashboardCardComponent>        
            </div> 
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Number of Activations Per Month</span>
                        </h5>
                    <SimpleLineChart _data = {activationPerMonth}  />
                </div>
                </DashboardCardComponent>        
            </div>  
        </div> 
    </>
  )
}
