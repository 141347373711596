import React, {useEffect, useState} from 'react'
import api from '../../../store/api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function AssetHistory() {

    const {organization, token, user} = useSelector((state) => {
        return {
          'organization': state.loginSlice.organization, 
          'token': state.loginSlice.token,
          'user': state.loginSlice.user
        }
    });

    const { id } = useParams() 
    const [assetHistory, setAssetHistory] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);

    const openLightbox = (images, index) => {
        setCurrentImages(images);
        setPhotoIndex(index);
        setIsOpen(true);
    };

    function revealTimelineItems() {
        var items = document.querySelectorAll('.timeline-item');
        items.forEach(function(item) {
            var position = item.getBoundingClientRect().top;
            var windowHeight = window.innerHeight;
            if (position < windowHeight - 100) {
                item.classList.add('visible');
            }
        });
    }

    const getAssetHistory = () => {
        let url = `/api/assets/asset-history/${id}/`
        if (user.user_type === 'client') {
            url = `/api/assets/asset-history/${id}/${organization.id}/`
        }
        api.get(url,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            setAssetHistory(response.data.data)
        })
        .catch((error) => {
            throw new Error(error)
        })
    }

    useEffect(() => {
        revealTimelineItems()
        window.addEventListener('scroll', revealTimelineItems);
        getAssetHistory()
    }, [])

  return (
  
    <div>
        {assetHistory.length === 0 ?
        <center>No asset histories found.</center> :
        <>
            <div className="timeline-container">
            <div className="timeline-container">
            {assetHistory &&
                assetHistory.map((history, index) => (
                    <div
                        key={index}
                        className={`timeline-item ${
                            index % 2 === 0 ? "left" : "right"
                        }`}
                    >
                        <div className="timeline-content">
                            <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                <img
                                src={history.asset_brand.image}
                                alt={`${history.asset_brand.name} Avatar`}
                                className="timeline-avatar"
                                />
                                <h4 className="timeline-title">
                                        {history?.activation?.name}
                                </h4>
                            </div>
                            <div style={{ textAlign: "-webkit-center", marginTop: "20px" }}>
                                {((history.dispatch_asset_media && history.dispatch_asset_media.length) || 
                                (history.receipt_asset_media && history.receipt_asset_media.length)) > 0 && (
                                            <>
                                                {/* Render first image */}
                                                <img
                                                    src={history.is_dispatch ? history.dispatch_asset_media[0].media_path :
                                                         history.is_receipt ? history.receipt_asset_media[0].media_path : null}
                                                    width="290"
                                                    height="260"
                                                    className="enlarge-on-hover"
                                                    onClick={history.is_dispatch ? () => openLightbox(history.dispatch_asset_media, 0) : history.is_receipt ? 
                                                            () => openLightbox(history.receipt_asset_media, 0) : null}
                                                    alt={history.activation.name || "Image"}
                                                    style={{ maxHeight: '200px', cursor: "pointer", objectFit: "cover", borderRadius: "5px" }}
                                                />
                                            </>
                                        )}
                                        <p className="timeline-description">
                                            {history.activation.description}
                                        </p>
                                        <div style={{display:'flex', justifyContent: 'space-around', alignItems: 'center'}}> 
                                            <div style={{ textAlign: "left" }}>
                                                {history.is_dispatch ? (
                                                    <span className="badge badge-primary">
                                                        Dispatch Operation
                                                    </span>
                                                ) : history.is_receipt ? (
                                                    <span className="badge badge-primary">
                                                        Receival Operation
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="timeline-date">
                                                    {history.activation.event_date}
                                            </div>
                                        </div>
                                        

                                <div className="d-flex flex-wrap ftw-bold fs-6 pe-2 mt-2" style={{justifyContent: 'center'}}>
                                    <span className="d-flex align-items-center text-gray-400 text-hover-primary me-3 mb-2">
                                        <span className="svg-icon svg-icon-2x me-1">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z"
                                                    fill="black"
                                                ></path>
                                            </svg>
                                        </span>
                                        {history.asset_brand.name}
                                    </span>
                                    <a className="d-flex align-items-center ftw-bold text-gray-400 text-hover-primary me-3 mb-2">
                                        <span className="svg-icon svg-icon-2x me-1">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="25"
                                                viewBox="0 0 24 25"
                                                fill="none"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z"
                                                    fill="black"
                                                ></path>
                                                <path
                                                    d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z"
                                                    fill="black"
                                                ></path>
                                            </svg>
                                        </span>
                                        {history.asset_project.name}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            {/* Render Lightbox Outside the Loop */}
            {isOpen && currentImages.length > 0 && (
                <Lightbox
                    mainSrc={currentImages[photoIndex]?.media_path}
                    nextSrc={
                        currentImages.length > 1
                            ? currentImages[(photoIndex + 1) % currentImages.length].media_path
                            : null
                    }
                    prevSrc={
                        currentImages.length > 1
                            ? currentImages[
                                  (photoIndex + currentImages.length - 1) %
                                      currentImages.length
                              ].media_path
                            : null
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + currentImages.length - 1) % currentImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % currentImages.length)
                    }
                    imageCaption={
                        <div style={{ padding: "10px", color: "#fff" }}>
                            {currentImages[photoIndex]?.comment}
                        </div>
                    }
                />
            )}
        </div>
            </div>
            </>}
        </div>
  )
}
